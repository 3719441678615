

.form-floating {
  label {
    color: #888;
    font-size: .9em;
  }
}

label.urlpath {
  padding-top: .5rem!important;
}

